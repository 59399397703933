import React from 'react'
import "./styles/UseCases.css"
import u1 from "../images/icons-usecase/u1.png"
import u2 from "../images/icons-usecase/u2.png"
import u3 from "../images/icons-usecase/u3.png"
import u4 from "../images/icons-usecase/u4.png"

export default function UseCases() {
  return (
    <div className='products-container' id='usecases'>
        <h1>Our Usecases</h1>
        <div className="usecases">
            <div className="usecase">
                <img src={u1} alt="" />
                <h3>Freestyle Drone Racing</h3>
                <p>Race your UAV at high speeds</p>
            </div>
            <div className="usecase">
                <img src={u2} alt="" />
                <h3>Educational Drones</h3>
                <p>Get started with Unmanned Aerial Systems </p>
            </div>
            <div className="usecase">
                <img src={u3} alt="" />
                <h3>Agricultural Drones</h3>
                <p>Simplify farming through UAS technology</p>
            </div>
            <div className="usecase">
                <img src={u4} alt="" />
                <h3>Drones for Defence</h3>
                <p>Develop simple solutions for complex problems through a modular platform </p>
            </div>
        </div>
    </div>
  )
}
