import React from "react";
import "./styles/Hero.css"
//import heroImage from "../images/heroImage.png"
import cover1 from "../images/cover1.png"
//import cover2 from "../images/cover2.png"

export default function Hero() {
  return (
    <div className="hero-container" id="hero">
      <div className="hero-text">
        <h1>UAS</h1>
        <h2>Unmanned Aerial Systems</h2>
        <p>
        A company focussed on building Unmanned Aerial Systems (UAS) with a focus towards simplicity.
        </p>
        <p style={{marginTop:0}}>We’re a deep-tech start-up and through continuous innovation, research and development we develop simplistic solutions involving complex Unmanned Aerial Systems (UAS).</p>
      </div>
      <img src={cover1} alt="" />
    </div>
  );
}
