import React, { useState } from 'react'
import logo from "../images/logo.png"
import "./styles/Nav.css"

export default function Nav() {

  const scrollTop = () => {
    const hero = document.getElementById("hero");
    hero.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollContact = () => {
    const contact = document.getElementById("contact-form");
    contact.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollProduct = () => {
    const product = document.getElementById("products");
    product.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollUsecase = () => {
    const usecase = document.getElementById("usecases");
    usecase.scrollIntoView({ behavior: 'smooth' });
  }

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (

    <header >
      <nav className='navbar'>
        <img className='logo-cs' src={logo} alt="" />
        <div className="hamburger-button">
          <button
            className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            {/* icon from heroicons.com */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className={isNavExpanded ? "nav-right expanded" : "nav-right"}>
        <ul className='nav-right'>
          <li onClick={scrollTop}>Home</li>
          <li onClick={scrollProduct}>Products</li>
          <li onClick={scrollUsecase}>Use Cases</li>
          <li className='nav-btn'><button onClick={scrollContact} >Contact Us</button></li>
        </ul>
        </div>
      </nav>
    </header>

  )

}
