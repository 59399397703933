import React, { useState } from "react";
import "./styles/ContactModal.css";
// import fpv from "../images/form/fpv.png";
// import agri from "../images/form/agri.png";
// import edu from "../images/form/edu.png";
// import surv from "../images/form/surv.png";
// import wild from "../images/form/wild.png";
// import map from "../images/form/map.png";
// import def from "../images/form/def.png";
// import tick from "../images/form/tick.png";
import { userStore } from "../services/firebaseconfig";
import { addDoc } from "firebase/firestore";

var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/; //email pattern

const ContactForm = () => {
  const handleChange = (e) => {
    const { value } = e.target;
    setUser({
      ...user,
      use_case: {
        ...user.use_case,
        [value]: !user.use_case[value],
      },
    });
  };

  const handleNewsletter = (e) => {
    const { value } = e.target;
    setUser({ ...user, newsletter: { [value]: !user.newsletter[value] } });
  };

  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    organisation: "",
    message: "",
    use_case: {
      fpv: false,
      agriculture: false,
      educational: false,
      surveillance: false,
      wildlife: false,
      mapping: false,
      defense: false,
    },
    newsletter: false,
  });

  console.log(user);

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  async function writeToDb() {
    const { name, email, phone, organisation, message, use_case, newsletter } =
      user;
    var shownameerror = document.getElementById("shownameerror");
    var alert = document.getElementById("alert")
    var alertContent = document.getElementById("alert-content")
    if (name === "") {
      shownameerror.textContent = "Please Enter a valid name";
      shownameerror.style.color = "red";
    } else if (email.match(emailPattern) === null) {
      //window.alert("Please enter a valid email address");
      shownameerror.textContent = "Please Enter a valid email";
      shownameerror.style.color = "red";
    } else if (phone === "") {
      shownameerror.textContent = "Please Enter a valid number";
      shownameerror.style.color = "red";
    } else {
      alert.style.display = "block"
      alertContent.textContent = "Form Submitted!"
      delay(5000)
      const docData = await addDoc(userStore, {
        name: name,
        email: email,
        phone: phone,
        organisation: organisation,
        message: message,
        use_case: use_case,
        newsletter: newsletter,
      });
      
      console.log(docData);
      window.location.reload();
      window.scrollTo(0, 0);
    }
  }

  return (
    <div className="contact-form-container" id="contact-form">
      <h1 style={{ fontSize: "40px" }}>Contact Us</h1>
      <div className="alert" id="alert"><p id="alert-content">Hello</p></div>
      <section className="modal-main contact-form">
        <div className="modal-header">
          <h3>Drop a message</h3>
          <h4>We’d love to hear from you</h4>
        </div>
        <div className="modal-form-component">
          <form className="modal-form" action="submit">
            <input
              type="text"
              placeholder="Name"
              name="name"
              autoComplete="off"
              className="form-check"
              value={user.name}
              onChange={(e) => setUser({ ...user, name: e.target.value })}
            />
            <input
              type="text"
              placeholder="Email Id"
              name="email"
              autoComplete="off"
              className="form-check"
              value={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
            <input
              type="number"
              placeholder="Contact number"
              name="phone"
              autoComplete="off"
              className="form-check"
              value={user.phone}
              onChange={(e) => setUser({ ...user, phone: e.target.value })}
            />
            <input
              type="text"
              placeholder="Organisation / Institution name (if any)"
              name="organisation"
              autoComplete="off"
              className="form-check"
              value={user.organisation}
              onChange={(e) =>
                setUser({ ...user, organisation: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Message to Us"
              name="organisation"
              autoComplete="off"
              className="form-check"
              value={user.message}
              onChange={(e) => setUser({ ...user, message: e.target.value })}
            />
            <div className="form-checkbox">
              <p style={{ fontSize: "18px" }}>
                Which type of UAS(s) or use-cases are you interested in?
              </p>
              <div className="options">
                <input
                  type="checkbox"
                  id="fpv"
                  name="fpv"
                  value="fpv"
                  onChange={handleChange}
                />
                Freestyle flying / Racing (FPV)
              </div>
              <div className="options">
                <input
                  type="checkbox"
                  id="agriculture"
                  name="agriculture"
                  value="agriculture"
                  onChange={handleChange}
                />
                Agricultural drones
              </div>
              <div className="options">
                <input
                  type="checkbox"
                  id="educational"
                  name="educational"
                  value="educational"
                  onChange={handleChange}
                />
                Educational use
              </div>
              <div className="options">
                <input
                  type="checkbox"
                  id="surveillance"
                  name="surveillance"
                  value="surveillance"
                  onChange={handleChange}
                />
                Aerial surveillance and monitoring systems
              </div>
              <div className="options">
                <input
                  type="checkbox"
                  id="wildlife"
                  name="wildlife"
                  value="wildlife"
                  onChange={handleChange}
                />
                Wildlife use
              </div>
              <div className="options">
                <input
                  type="checkbox"
                  id="mapping"
                  name="mapping"
                  value="mapping"
                  onChange={handleChange}
                />
                Mapping
              </div>
              <div className="options">
                <input
                  type="checkbox"
                  id="defense"
                  name="defense"
                  value="defense"
                  onChange={handleChange}
                />
                Defence
              </div>
            </div>
            <div id="newsletter" className="options">
              <input
                type="checkbox"
                id="newsletter"
                name="newsletter"
                value="newsletter"
                onChange={handleNewsletter}
              />
              <p>
                Do you want to receive updates on our story and announcements
                and launches?
              </p>
            </div>
          </form>
          <div className="brand-details">
            <div className="detail">
              {/* <div className="square"></div> */}
              <div className="detail-desc">
                <p style={{ color: "#F2771A" }}>Email us @</p>
                <p>info@spektroaviation.com</p>
              </div>
            </div>
          </div>
        </div>
        <div id="shownameerror"></div>
        <button type="submit" className="form-submit-btn" onClick={writeToDb}>
          Send
        </button>
      </section>
    </div>
  );
};

export default ContactForm;
