import React from 'react'
import "./styles/Footer.css"
import{FiMail} from "react-icons/fi"
// import {MdOutlinePhoneInTalk} from "react-icons/md"
// import {AiOutlineArrowUp} from "react-icons/ai"

export default function Footer() {
  return (
        <footer className='footer-component'>
            <div className="contact">
                <div className="contact-desc">
                    <FiMail color='orange'/>
                    <p>info@spektroaviation.com</p>
                </div>
                {/* <div className="contact-desc">
                    <MdOutlinePhoneInTalk color='orange'/>
                    <p>+91 12354 78965</p>
                </div> */}
            </div>
            {/* <div className="mail-us">
                <h3>For any queries enter your mail</h3>
                <h4>We’ll reach out to you asap!</h4>
                <div className="custom-input">
                    <input type="text" placeholder='Enter Your Email' />
                    <button type='submit'><AiOutlineArrowUp color='white' size={20}/></button>
                </div>
                <p>We don’t spam!</p>
            </div> */}
        </footer>
  )
}
