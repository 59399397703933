import React from 'react'
import "./styles/Products.css"
//import product from "../images/product.png"
import p1 from "../images/icons-products/p1.png"
import p2 from "../images/icons-products/p2.png"
import p3 from "../images/icons-products/p3.png"
import p4 from "../images/icons-products/p4.png"

export default function Products() {
  return (
    <div className='products-container' id='products'>
        <h1>Our Products and Services</h1>
        <div className="products">
            <div className="product">
              <img src={p1} alt="" />
              <p>Flight Controllers</p>
            </div>
            <div className="product">
              <img src={p2} alt="" />
              <p>Custom UAS</p>
            </div>
            <div className="product">
              <img src={p3} alt="" />
              <p>Consultation Services for UAS</p>
            </div>
            <div className="product">
              <img src={p4} alt="" />
              <p>UAS Software & Automation</p>
            </div>
        </div>
    </div>
  )
}
