import './App.css';
//import ComingSoon from './Components/ComingSoon';
import ContactForm from './Components/ContactForm';
//import DroneSection from './Components/DroneSection';
import Footer from './Components/Footer';
import Hero from './Components/Hero';
import Nav from './Components/Nav';
import NewLaunch from './Components/NewLaunch';
import Products from './Components/Products';
import UseCases from './Components/UseCases';

function App() {
  return (
    <>
      <Nav />
      <Hero />
      <Products/>
      <UseCases/>
      <ContactForm/>
      <NewLaunch/>
      <Footer />
    </>
  );
}

export default App;
