import { initializeApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";

const firebaseConfig = initializeApp({
  apiKey: "AIzaSyDR0ba3uYJTPqet5WMfi0RYRfBGnV46M9U",
  authDomain: "spektro-aviation.firebaseapp.com",
  projectId: "spektro-aviation",
  storageBucket: "spektro-aviation.appspot.com",
  messagingSenderId: "921109024049",
  appId: "1:921109024049:web:7b22a6d897fbdd3bc27410",
  measurementId: "G-H9ZBVXBZC4"
});

// const firebaseConfig = initializeApp({
//   apiKey: "AIzaSyCpkBtz47sEzN9rlqlDF-SHP4LryIlOrmg",
//   authDomain: "test-db-10d72.firebaseapp.com",
//   projectId: "test-db-10d72",
//   storageBucket: "test-db-10d72.appspot.com",
//   messagingSenderId: "186405944038",
//   appId: "1:186405944038:web:3956e68b843166a349d927"
// });

const db = getFirestore(firebaseConfig);

const userStore = collection(db, 'leads')

//const userStore = collection(db, 'test')

export {userStore}





