import React from "react";
import "./styles/NewLaunch.css"
import comingsoon_fc from "../images/comingsoon_fc.png"
import comingsoon_mdp from "../images/comingsoon_mdp.png"

export default function NewLaunch() {
  return (
    <div className="newlaunch-container" id="newlaunch">
      <h1>Coming Soon</h1>
      <div className="launch-cards">
        <div className="launch-card">
          <div className="launch-card-desc">
            <h2>Flight controller board</h2>
            <p>
              We’re launching our own in-house designed Flight Controller Board
              (FCB) for Unmanned Aerial Systems. Initially, we will be targeting
              FPV and freestyle segments. These controller boards will support
              open source Flight Firmware and will follow current standards.
            </p>
          </div>
          <img src={comingsoon_fc} alt="placeholder" />
        </div>
        <div className="launch-card">
          <div className="launch-card-desc">
            <h2>Modular drone platform</h2>
            <p>
              Our thought process lies in the fact that having a modular drone
              platform, a lot of use-cases can be solved. Thus, we’re investing
              a lot into the development of a framework consisting of both
              software and hardware to modularise the approach of developing UAS
              for multiple scenarios. We’re currently in the development phase
              and expecting to launch proof of concepts soon.
            </p>
          </div>
          <img src={comingsoon_mdp} alt="placeholder" />
        </div>
      </div>
    </div>
  );
}
